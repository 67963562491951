<template>
  <div>
    <div class="uk-margin-top uk-card uk-card-default uk-padding simp-card">
      <form
        class="uk-form-stacked"
        data-vv-scope="form-harvest-request"
        @submit.prevent="onUpload('form-harvest-request')"
      >
        <div class="uk-margin-bottom font-filter uk-text-bold">
          Import Data {{ title }}
        </div>
        <div class="uk-margin-small-bottom uk-text-bold uk-text-large">
          1. Pilih file yang ingin diupload:
        </div>
        <div>
          <p>Pilih Bagian: </p>
          <div class="uk-form-controls">
            <multiselect
              v-model="partChoose"
              v-validate="'required'"
              track-by="value"
              label="value"
              name="part-choose"
              :show-labels="true"
              placeholder="Semua"
              open-direction="bottom"
              :options="sapronakParts"
              :searchable="true"
              :loading="false"
              :close-on-select="true"
              :class="{'uk-form-danger': errors.has('part-choose')}"
              :max-height="500"
              :show-no-results="true"
            >
              <span slot="noResult">Oops! Data tidak ditemukan.</span>
            </multiselect>
          </div>
          <span
            v-show="errors.has('part-choose')"
            class="uk-text-small uk-text-danger"
          >{{ errors.first('part-choose') }}</span>
        </div>
        <div
          class="uk-text-small uk-text-secondary uk-margin"
        >
          <span v-if="partChoose">
            Berikut ini template file excel dan pastikan upload sesuai template
            <a :href="`/files/template-import/${templateLink}`">Klik disini</a>
          </span>
        </div>
        <template v-if="partChoose">
          <div class="uk-margin uk-margin-top">
            <p>Pilih File: </p>
            <p v-if="file_import">
              {{ file_import.name }}
            </p>
          </div>
          <div class="uk-margin">
            <div uk-form-custom="target: true">
              <input 
                ref="file_import" 
                v-validate="'required'"
                type="file"
                name="file"
                accept="text/csv"
                :class="{'uk-form-danger': errors.has('form-harvest-request.file')}"
                @change="handleFileUpload"
              >
              <input
                class="uk-input uk-form-width-large"
                type="text"
                placeholder="Pilih .csv file"
                disabled
              >
              <button
                class="uk-button uk-button-upload"
              >
                Browse
              </button>
            </div>
          </div>
      
          <span
            v-show="errors.has('form-harvest-request.file')"
            class="uk-text-small uk-text-danger"
          >{{ errors.first('form-harvest-request.file') }}</span>
        </template>
        <div class="uk-margin-top uk-margin-small-bottom uk-text-bold uk-text-large">
          2. Upload Disini:
        </div>
        <div class="uk-margin-small-bottom uk-form-controls">
          <button
            class="uk-button uk-button-primary uk-width-xlarge uk-margin-small-button"
            :disabled="btn_upload"
          >
            <span
              v-show="btn_upload != true"
              uk-icon="icon: upload; ratio: 0.9"
              class="uk-margin-small-right"
            />Upload Disini
          </button> 
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  props:{
    title:{
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      file_import: null,
      btn_upload: true,
      partChoose: '',
      sapronakParts: [
        {
          value: 'Stock Transfer',
          code: 0
        }, 
        {
          value: 'Detail Stock Transfer',
          code: 1
        }
      ],
      templateLink: ''
    }
  },
  computed:{
    ...mapGetters({
      responseImportSt: 'sapronak/responseSt',
      responseImportStDetail: 'sapronak/responseStDetail'
    })
  },
  watch:{
    responseImportSt(){
      if(this.responseImportSt.message == 'OK'){
        notificationSuccess(`${this.title} berhasil di import`)
        this.btn_upload = false
        // this.resetInput()
        setTimeout(() => {
          window.location.href = '/admin/sapronak'
        }, 1700)
      } else {
        this.resetInput()
      }
    },
    responseImportStDetail(){
      if(this.responseImportStDetail.message == 'OK'){
        notificationSuccess(`${this.title} berhasil di import`)
        this.btn_upload = false
        // this.resetInput()
        setTimeout(() => {
          window.location.href = '/admin/sapronak'
        }, 1700)
      } else {
        this.resetInput()
      }
    },
    partChoose(){
      if(!this.partChoose){
        this.resetInput()
        this.btn_upload = true
      } else {
        if(this.partChoose.code == 0){
          this.templateLink = 'Import-Stock-Transfer.csv'
        } else {
          this.templateLink = 'Import-Stock-Transfer-Detail.csv'
        }
      }
    }
  },
  methods: {
    ...mapActions({
      importSt: 'sapronak/importSt',
      importStDetail: 'sapronak/importStDetail'
    }),
    async onUpload(scp) {
      this.btn_upload = true
      this.$validator.validateAll(scp).then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.uploadAction()
        } else {
          this.btn_upload = false
        }
      })
    },
    async uploadAction() {
      const formData = new FormData()
      formData.append('file', this.file_import)
      if(this.partChoose.code == 0){
        this.importSt(formData)
      } else {
        this.importStDetail(formData)
      }

    },
    handleFileUpload(){
      this.file_import = this.$refs.file_import.files[0]
      this.btn_upload = false
    },
    resetInput() {
      this.btn_upload = true
      this.file_import = null
      this.$refs.file_import.value = null
    }
  }
}
</script>

<style scoped>
.uk-button-upload{
  position: absolute;
  right: 0px;
  background: #F0F0F0;
  border: 1px solid #D4DFF5;
  border-radius: 0px 4px 4px 0px;
  box-shadow: none;
}
.uk-text-large{
  color: black;
}
p{
  color: #6D7278;
}
.uk-form-controls{
  width: 500px;
  color:#4DA0E7;
}
</style>
